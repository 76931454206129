import React, { useState, useEffect, useRef } from 'react';
import './App.scss';
import backgroundHeader from './background-header.png';

import postcard1Front from './postcards/HS-G-PC-02.jpg';
import postcard1Back from './postcards/BACK.jpg';
import postcard2Front from './postcards/HS-G-PC-10.jpg';
import postcard2Back from './postcards/BACK-2.jpg';

const App = () => {

  const postcards = [
    { key: 1, front: postcard1Front, back: postcard1Back },
    { key: 2, front: postcard2Front, back: postcard2Back },
  ];
  const nameInputRef = useRef<HTMLInputElement>(null);
  const noteInputRef = useRef<HTMLTextAreaElement>(null);

  const [selectedPostcard, setSelectedPostcard] = useState(postcards[0]);
  const [name, setName] = useState('');
  const [note, setNote] = useState('');
  const [customizedPostcardBackImageData, setCustomizedPostcardBackImageData] = useState('');
  const [customizedPostcardImageData, setCustomizedPostcardImageData] = useState('');

  const [showCustomize, setShowCustomize] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptField, setPromptField] = useState('name');

  useEffect(() => {
    if (showPrompt) {
      switch (promptField) {
        case 'name':
          nameInputRef.current!.select();
          break;
        case 'note':
          noteInputRef.current!.select();
          break;
      }
    }
  }, [showPrompt, promptField]);

  const drawCustomPostcard = async (postcard: any, name: string, note: string) => {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    const frontImage = await ImageWithPromise(selectedPostcard.front);
    const backImage = await ImageWithPromise(selectedPostcard.back);

    // back only
    canvas.width = 900;
    canvas.height = 600;
    ctx!.font = "30px Champion";
    ctx!.drawImage(backImage, 0, 0, 900, 600);
    if (name !== '') {
      ctx!.fillText(`${name},`, 530, 230);
    }
    getLines(ctx, note, 290).forEach((line, lineNumber) => {
      ctx!.fillText(line, 530, 296 + lineNumber * 48);
    });
    setCustomizedPostcardBackImageData(canvas.toDataURL());

    // front and back
    canvas.height = 1200;
    ctx!.font = "30px Champion";
    ctx!.drawImage(frontImage, 0, 0, 900, 600);
    ctx!.drawImage(backImage, 0, 600, 900, 600);
    if (name !== '') {
      ctx!.fillText(`${name},`, 530, 830);
    }
    getLines(ctx, note, 290).forEach((line, lineNumber) => {
      ctx!.fillText(line, 530, 896 + lineNumber * 48);
    });
    setCustomizedPostcardImageData(canvas.toDataURL());
  }

  useEffect(() => {
    if (!showPrompt) {
      drawCustomPostcard(selectedPostcard, name, note);
    }
  }, [selectedPostcard, name, note, showPrompt])

  const ImageWithPromise = (src: string) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    })
  }

  const getLines = (ctx: any, text: string, maxWidth: number) => {
    var words = text.split(' ');
    var lines = [];
    var currentLine = words[0];

    for (var i = 1; i < words.length; i++) {
      var word = words[i];
      var width = ctx.measureText(currentLine + " " + word).width;
      if (width < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  }

  return (
    <>
      <header className="l-0 t-0 w-100 py-5" style={{ zIndex: 100, background: `#000 url(${backgroundHeader}) top right no-repeat`, backgroundSize: '202.5px 89.5px' }}>
        <div className="container text-center">
          <h1 className="display-1 text-uppercase text-warning mb-0">Golden</h1>
        </div>
      </header>

      <main className="container d-flex align-items-center">
        <div className={'fade ' + (showCustomize ? '' : 'show')}>
          <div className="row pb-5 pb-md-4">
            {postcards.map((postcard) => (
              <div key={postcard.key} className="col-md-6">
                <div className="px-4 px-md-2 px-lg-4 pt-5 pt-md-4">
                  <div className="square position-relative">
                    <img className="position-absolute l-0 b-0 w-75 shadow" src={postcard.back} alt="You're So Golden" />
                    <img className="position-absolute t-0 r-0 w-75 shadow" src={postcard.front} alt="You're So Golden" />
                  </div>
                  <div className="pt-4 text-center">
                    <button className="btn btn-black text-uppercase text-warning" onClick={() => { setSelectedPostcard(postcard); setShowCustomize(true); }}>Customize</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={'fade ' + (showCustomize ? 'show' : '')}>
          <div className="row pb-5 pb-md-4">
            <div className="col-md-8">
              <div className="px-4 px-md-2 px-lg-4 pt-5 pt-md-4">
                <div className="square position-relative">
                  <img className="position-absolute t-0 r-0 w-75 shadow" src={selectedPostcard.front} alt="You're So Golden" />
                  <img className="position-absolute l-0 b-0 w-75 shadow" src={customizedPostcardBackImageData} alt="You're So Golden" />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 d-flex align-items-center">
              <div className="w-100 py-4">
                <button className="btn btn-black btn-block text-uppercase text-warning" onClick={() => { setPromptField('name'); setShowPrompt(true); }}>Add a name</button>
                <button className="btn btn-black btn-block text-uppercase text-warning" onClick={() => { setPromptField('note'); setShowPrompt(true); }}>Add a note</button>
                <a download="golden.png" href={customizedPostcardImageData} className="btn btn-black btn-block text-uppercase text-warning">Download</a>
                <button className="btn btn-text btn-block text-uppercase text-black text-monospace" onClick={() => setShowCustomize(false)}>Back</button>
              </div>
            </div>
          </div>
        </div>

        <div className={'position-fixed l-0 t-0 h-100 d-flex align-items-center fade ' + (showPrompt ? 'show' : '')} style={{ zIndex: 200, background: 'rgba(0, 0, 0, 0.7)' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                <div className="bg-warning p-3 rounded">
                  {promptField === 'name' && <>
                    <label className="text-uppercase">Enter Name</label>
                    <div className="form-group">
                      <input ref={nameInputRef} className="form-control" type="text" value={name} onChange={e => setName(e.target.value)} onKeyDown={e => e.key === 'Enter' && setShowPrompt(false)} />
                    </div>
                  </>}
                  {promptField === 'note' && <>
                    <label className="text-uppercase">Enter Note</label>
                    <div className="form-group">
                      <textarea ref={noteInputRef} className="form-control" value={note} maxLength={50} onChange={e => setNote(e.target.value.replace(/(\r\n|\n|\r)/gm, ''))} onKeyDown={e => e.key === 'Enter' && setShowPrompt(false)} />
                    </div>
                  </>}
                  <button className="btn btn-block btn-black text-uppercase" onClick={() => setShowPrompt(false)}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="l-0 b-0 w-100 p-2 bg-black small text-monospace text-uppercase text-center text-secondary" style={{ background: '#000' }}>
        &copy; 2020 Sony Music Entertainment
        <a href="https://www.sonymusic.com/feedback/" className="d-inline-block px-2 text-secondary" target="_blank" rel="noopener noreferrer">Send Feedback</a>
        <a href="http://www.sonymusic.com/privacypolicy.html" className="d-inline-block px-2 text-secondary" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        <a href="https://www.sonymusic.com/ccpa-contact-form/" className="d-inline-block px-2 text-secondary" target="_blank" rel="noopener noreferrer">Do Not Sell My Personal Information</a>
        <a href="https://www.sonymusic.com/privacy-policy/#your-california-privacy-rights" className="d-inline-block px-2 text-secondary" target="_blank" rel="noopener noreferrer">Your California Privacy Rights</a>
        <a href="http://www.sonymusic.com/privacy/termsandconditions.html" className="d-inline-block px-2 text-secondary" target="_blank" rel="noopener noreferrer">Terms&nbsp;and&nbsp;Conditions</a>

      </footer>
    </>
  );
}

export default App;
